// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'User Management',
    path: '/dashboard/user-management',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Books Management',
    path: '/dashboard/books-management',
    icon: getIcon('eva:book-open-fill'),
    children: [
      {
        title: 'Books Category',
        path: '/dashboard/books-category',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'Books',
        path: '/dashboard/books',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'Clone Books',
        path: '/dashboard/clone-books',
        icon: getIcon('eva:shopping-bag-fill'),
      },
    ],
  },
  {
    title: 'Exam Management',
    path: '/dashboard/exams-management',
    icon: getIcon('healthicons:i-exam-multiple-choice-negative'),
  },
  {
    title: 'Subscriptions',
    path: '/dashboard/subscriptions',
    icon: getIcon('solar:dollar-line-duotone'),
  },
  {
    title: 'Website Management',
    path: '/dashboard/website-management',
    icon: getIcon('eva:globe-fill'),
    children: [
      {
        title: 'Banner Slider',
        path: '/dashboard/banner-slider',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'How it work',
        path: '/dashboard/how-it-work',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'About Us',
        path: '/dashboard/about-us',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'Contact Us',
        path: '/dashboard/contact-us',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'Careers',
        path: '/dashboard/career',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'Teach a book',
        path: '/dashboard/site-forms/teach',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'Become an author',
        path: '/dashboard/site-forms/author',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'FAQ',
        path: '/dashboard/faq',
        icon: getIcon('eva:shopping-bag-fill'),
      },
    ],
  },
  {
    title: 'Blogs',
    path: '/dashboard/blog',
    icon: getIcon('eva:file-text-fill'),
    children: [
      {
        title: 'My Blogs',
        path: '/dashboard/my-blogs',
        icon: getIcon('eva:shopping-bag-fill'),
      },
      {
        title: 'Blogs Category',
        path: '/dashboard/blog-category',
        icon: getIcon('eva:shopping-bag-fill'),
      },
    ],
  },
  // {
  //   title: 'Forum',
  //   path: '/dashboard/forum',
  //   icon: getIcon('eva:message-square-fill'),
  // },

  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
