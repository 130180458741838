import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { BsInstagram } from 'react-icons/bs';
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { GrFacebookOption } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import aBooks from '../../assets/webImages/AdaptLogo.png';
const Webfooter = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img alt="Logo" src={aBooks} className="img-fluid" />
              <p>
                {t('footer_description_1')} <br /> {t('footer_description_2')}
              </p>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 ">
              <h5>{t('footer_h_1')}</h5>
              <div className="row">
                <div className="col-sm-12 col-md-6  ">
                  <ul>
                    <li>
                      <Link to="/become-an-author">{t('footer_a_1')}</Link>
                    </li>
                    <li>
                      <Link to="/teach-a-book">{t('footer_a_2')}</Link>
                    </li>
                    {/* <li>
                      <Link to="/team">Team</Link>
                    </li> */}
                    <li>
                      <a
                        href="https://docs.google.com/document/d/1zQ0nw_PIG6BfWej6OSEtE85CT7-KRjqz3Vr3r0272w4/edit?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('footer_a_4')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.google.com/document/d/1Xn-SbBebV7JscL-Z0bBTXyWHqEqhqNkinWTgNVSfcE4/edit?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('footer_a_terms')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-6 ">
                  <ul>
                    {/* <li>
                      <Link to="/about">About Us</Link>
                    </li> */}
                    <li>
                      <Link to="/careers">{t('footer_a_3')}</Link>
                    </li>
                    <li>
                      <Link to="/contact"> {t('footer_a_5')}</Link>
                    </li>
                    <li>
                      <Link to="/forum"> {t('footer_a_6')}</Link>
                    </li>
                    <li>
                      <Link to="/blog"> {t('footer_a_7')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 ">
              <h5>{t('footer_social_media')}</h5>
              <div className="d-flex">
                <GrFacebookOption className="icon" />
                <BsInstagram className="icon" />
                <FaLinkedinIn className="icon" />
                <FaTwitter className="icon" />
              </div>
              <Typography>
                <Trans i18nKey="footer_adress" />
              </Typography>
            </div>

            <div className="col-12">
              <hr className="" style={{ color: 'white' }}></hr>
              <p className="para">Copyright © 2024 Avidemia</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Webfooter;
