import { Box, Button as ButtonMui, useMediaQuery } from '@mui/material';
import MuiLink from '@mui/material/Link';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Container, Nav, NavDropdown, Navbar, NavbarBrand } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Storage } from 'src/Storage';
import ChangeLang from 'src/components/ChangeLang/ChangeLang';
import LoginModal from 'src/sections/PopUp/LoginModal';
import SignUpModal from 'src/sections/PopUp/SignUpModal';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import aBooks from '../../assets/webImages/logo.webp';

const Image_Url = process.env.REACT_APP_API_IMAGEURL;

const Header = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const { t } = useTranslation();
  const isLogged = useSelector((state) => state.authDetail.UserData);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [expanded, setExpanded] = useStateWithCallbackLazy(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    setIsUserLoggedIn(Storage.get('authToken'));
  }, [isLogged]);

  useEffect(() => {
    if (isUserLoggedIn && isUserLoggedIn.token) {
      axios
        .get(process.env.REACT_APP_API_BASEURL + 'user/profile-details', {
          headers: {
            Authorization: isUserLoggedIn.token,
          },
        })
        .then((res) => {
          setUserProfile(res.data.data);
        });
    }
  }, [isUserLoggedIn]);

  const logOutHandler = () => {
    Storage.remove('authToken');
  };

  const getUserImage = () => {
    if (userProfile?.image) {
      if (userProfile.image.includes('https://')) {
        return userProfile.image;
      } else {
        return Image_Url + userProfile.image;
      }
    } else {
      if (loggedInData?.image) {
        if (loggedInData?.image.includes('https://')) {
          return loggedInData?.image;
        } else {
          return Image_Url + loggedInData?.image;
        }
      }
    }
  };

  const loggedInData = isUserLoggedIn && JSON.parse(isUserLoggedIn);
  const isUserRole = loggedInData?.user_role;
  const isInstructor = isUserRole && isUserRole.some((v) => v === 'INSTRUCTOR' || v === 'ADMIN' || v === 'SUPER_ADMIN');

  const isStudent = isUserRole && isUserRole.some((v) => v === 'STUDENT');

  const onLinkClick = (path) => {
    if (expanded) {
      setExpanded(false, () => {
        navigate(path);
      });
    } else {
      navigate(path);
    }
  };

  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      {/*<div className='topbgimg  ' > </div>*/}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Navbar expanded={expanded} expand="lg" className="navbar pt-0 header-section">
          <Container fluid={isMobile} style={{ padding: isMobile ? '0px' : undefined }}>
            <NavbarBrand style={{ display: 'flex', justifyContent: 'center' }}>
              <NavLink to="/" style={{ padding: '0' }}>
                <img alt="logo" src={aBooks} className="img-fluid" />
              </NavLink>
            </NavbarBrand>
            <Navbar.Toggle
              onClick={() => {
                setExpanded((state) => !state);
              }}
              aria-controls="navbarScroll"
            />
            <Navbar.Collapse
              id="navbarScroll"
              style={{ zIndex: '100', backgroundColor: expanded ? 'black' : 'transparent' }}
            >
              <Nav
                className="nave-left"
                style={{ height: isMobile ? '100vh' : undefined, padding: isMobile ? '20px' : undefined }}
              >
                <MuiLink
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onLinkClick('/library')}
                  className={`nav-link ${isLinkActive('/library') ? 'active' : ''}`}
                >
                  {t('nav_a_1')}
                </MuiLink>
                {isUserLoggedIn && (
                  <MuiLink
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onLinkClick('/my-library')}
                    className={`nav-link ${isLinkActive('/my-library') ? 'active' : ''}`}
                  >
                    {t('nav_a_2')}
                  </MuiLink>
                )}

                <MuiLink
                  sx={{ cursor: 'pointer' }}
                  className={`nav-link ${isLinkActive('/exam-booster') ? 'active' : ''}`}
                  onClick={() => onLinkClick('/exam-booster')}
                >
                  {t('nav_a_6')}
                </MuiLink>

                {isUserLoggedIn && (isInstructor || isStudent) && (
                  <MuiLink
                    sx={{ cursor: 'pointer' }}
                    className={`nav-link ${isLinkActive('/student-exam') ? 'active' : ''}`}
                    onClick={() => onLinkClick('/student-exam')}
                  >
                    {t('nav_a_homework')}
                  </MuiLink>
                )}

                <MuiLink
                  sx={{ cursor: 'pointer' }}
                  className={`nav-link ${isLinkActive('/about-us') ? 'active' : ''}`}
                  onClick={() => onLinkClick('/about-us')}
                >
                  {t('nav_a_3')}
                </MuiLink>

                <MuiLink
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onLinkClick('/pricing')}
                  className={`nav-link ${isLinkActive('/pricing') ? 'active' : ''}`}
                >
                  {t('nav_a_4')}
                </MuiLink>

                {isUserLoggedIn ? (
                  <NavDropdown
                    className="profile-dropdown"
                    title={
                      <div style={{ margin: '0px 10px' }}>
                        <img style={{ borderRadius: '100%' }} src={getUserImage()} width="30" height="30" alt="logo" />
                      </div>
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item>
                      <Link to="my-profile">{t('my_profile')}</Link>
                    </NavDropdown.Item>
                    {isUserRole && isUserRole.includes('SUPER_ADMIN') && (
                      <NavDropdown.Item>
                        <Link to="/dashboard/app">{t('admin_dashboard')}</Link>
                      </NavDropdown.Item>
                    )}
                    {isInstructor && (
                      <NavDropdown.Item>
                        <Link to="/instructor-exams">{t('exam_management')}</Link>
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item>
                      <Link to="change-password">{t('change_password')}</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logOutHandler}>{t('logout')}</NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <>
                    <ButtonMui
                      onClick={() => setOpenModal(true)}
                      sx={{ width: 'fit-content', mr: 1 }}
                      className="nav-link bg-transparent text-white border-0"
                    >
                      {t('sign_in')}
                    </ButtonMui>

                    <Button
                      type="button"
                      className="default-btn white-btn mb-2 mt-2 "
                      onClick={() => setOpenRegisterModal(true)}
                    >
                      {t('join_for_free')}
                    </Button>
                  </>
                )}
                <ChangeLang />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Box>

      <LoginModal open={openModal} setOpen={setOpenModal} setRegister={setOpenRegisterModal} />
      <SignUpModal open={openRegisterModal} setOpen={setOpenRegisterModal} setLogin={setOpenModal} />
    </>
  );
};

export default Header;
